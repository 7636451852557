const chartData = {
    type: 'pie',
    radius: ['40%', '60%'],
    label: {
        show: false,
        position: 'center',
        fontSize: 12,
        formatter: '{b}\n{d}%',
        color: '#2366cb'
    },
    select: {
        label: {
            show: true,
        }
    },
    startAngle: -90,
    selectedMode: 'single',
    selectedOffset: 0,
}

export const assChartOption = {
    color: ['#1f63ca', '#00a9fe', '#75d1ff', '#7087fc'],
    series: [
        {
            ...chartData,
            name: '僧众',
            center: ['17%', '50%'],
            data: [
                {value: 126, name: '实到', selected: true},
                {value: 0, name: '公假'},
                {value: 0, name: '病假'},
                {value: 0, name: '事假'},
            ]
        },
        {
            ...chartData,
            name: '职工',
            center: ['50%', '50%'],
            data: [
                {value: 128, name: '实到', selected: true},
                {value: 0, name: '公假'},
                {value: 0, name: '病假'},
                {value: 0, name: '事假'},
            ]
        },
        {
            ...chartData,
            type: 'pie',
            radius: ['40%', '60%'],
            name: '志工',
            center: ['83%', '50%'],
            data: [
                {value: 121, name: '签到人数', selected: true},
                {value: 0, name: '未签到', select: {disabled: true}},
            ]
        }
    ]
}

export const changeChartOption = {
    grid: {
        top: 20,
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: ['进单', '离单', '转正', '调岗', '外派'],
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 12,
            color: '#1f63ca'
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(31,99,202,0.3)'
            }
        }
    },
    yAxis: {
        type: 'value',
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 12,
            color: '#1f63ca'
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: 'rgba(31,99,202,0.3)'
            }
        },
        splitLine: {
            show: false
        }
    },
    series: [
        {
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#4887e6' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#1f63ca' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            barWidth: 16
        }
    ]
}
