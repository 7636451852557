<template>
  <div class="content md">
    <a-card title="个人信息" :body-style="{padding: '0 20px'}">
      <staff-brief :info="user.evaluate_user" v-if="user && user.mobile && user.evaluate_user"></staff-brief>
      <div class="home-info-tip" v-else>
        <span>您的资料尚未完善</span>
        <router-link replace :to="{name: 'HRBookForm', params: {type: 'staff'}, query: {b: user.wx_user_id}}" class="txt-btn">去完善</router-link>
      </div>
    </a-card>
    <div class="flex-box home-piece" v-if="role < 3">
      <a-card class="flex-grow" title="人员统计">
        <div class="flex-box align-center change-piece">
          <h3 class="change-title">人数统计</h3>
          <div class="flex-grow flex-box align-center">
            <div class="book-num-item">
              <span>僧众</span>
              <span>126</span>
            </div>
            <a-divider type="vertical"/>
            <div class="book-num-item">
              <span>职工</span>
              <span>128</span>
            </div>
            <a-divider type="vertical"/>
            <div class="book-num-item">
              <span>志工</span>
              <span>121</span>
            </div>
          </div>
        </div>
        <div class="flex-box align-center change-piece">
          <h3 class="change-title">考勤统计</h3>
          <div class="flex-grow flex-box book-edu-chart"></div>
        </div>
      </a-card>
      <a-card
        class="flex-grow flex-box vertical"
        style="margin-left: 16px"
        title="人员变动"
        :body-style="{flex: 1,display: 'flex'}">
        <div class="flex-grow flex-box vertical change-piece">
          <h3 class="change-title">僧众</h3>
          <div class="flex-grow flex-box align-center" id="sz_change">
          </div>
        </div>
        <div class="flex-grow flex-box vertical change-piece">
          <h3 class="change-title">职工</h3>
          <div class="flex-grow flex-box align-center" id="zg_change">
          </div>
        </div>
      </a-card>
    </div>
    <div class="flex-box home-piece">
      <a-card class="flex-grow" title="评价列表">
        <router-link slot="extra" :to="{name: 'Assessment'}">查看更多<a-icon type="right" /></router-link>
        <div class="home-ass-list" v-if="assList && assList.length > 0">
          <div class="home-ass-item" v-for="ass in assList" :key="ass.id">
            <div class="home-ass-name">{{ass.programName}}</div>
            <div class="home-ass-btn" @click="showAssDetail(ass)">查看详情<a-icon type="right" /></div>
          </div>
        </div>
        <a-empty :image="emptyImage" v-else-if="assList"/>
      </a-card>
      <a-card class="flex-grow" style="margin-left: 16px" title="动态通知">
        <router-link slot="extra" :to="{name: 'HRMessage'}">查看更多<a-icon type="right" /></router-link>
        <msg-list :list="msgList"></msg-list>
      </a-card>
    </div>
  </div>
</template>

<script>
    import * as echarts from "echarts";
    import { Empty } from 'ant-design-vue';
    import staffBrief from "@/components/staff-brief";
    import msgList from "@/layouts/MsgList";
    import {getUserWxId} from "@/common/js/storage";
    import {dealStaff} from "@/common/hr/book";
    import {getAssItemStatusText, getAssTypeName} from "../../common/hr/ass";
    import {assChartOption, changeChartOption} from "../../common/hr/chart";
    import {getHRRole} from "../../common/constant/org";
    import {clone} from "../../common/js/tool";
    import {getUser, getTemple} from "../../common/js/storage";

    export default {
        name: "HRHome",
        components: {
            staffBrief,
            msgList
        },
        data() {
            return {
                user: null,
                wxId: null,
                role: getHRRole(),
                emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
                assList: null,
                msgList: [
                    // {id: 1, title: '【入职公示】关于入职人员的公示！', create_time: '2022-03-22 17:43:34'}
                ]
            }
        },
        created() {
            this.getStaff();
            this.getAssList();
            this.getMsgList();
        },
        mounted() {
            if(this.role < 3) {
                this.initChart();
            }
        },
        methods: {
            getStaff() {
                const user = clone(getUser());
                if(user.evaluate_user) {
                    user.evaluate_user = dealStaff(user.evaluate_user);
                }
                this.user = user;
            },
            initChart() {
                this.chart = echarts.init(this.$el.querySelector(".file-edu-chart"));
                this.chart.setOption(assChartOption);
                this.szChangeChart = echarts.init(document.getElementById('sz_change'));
                let szOptions = clone(changeChartOption);
                this.szChangeChart.setOption(szOptions);
                this.zgChangeChart = echarts.init(document.getElementById('zg_change'));
                let zgOptions = clone(changeChartOption);
                this.zgChangeChart.setOption(zgOptions);
            },
            showAssDetail(record) {
                this.$store.commit("setDetail", {type: 'ass', obj: record})
                this.$router.push({name: "AssessmentDetail", params: {id: record.id}});
            },
            getAssList() {
                let url = `/admin/evaluate-item?sort=-id&expand=list&pageSize=5&filter[user_id]=${getUserWxId()}`;
                this.$axios(url).then(res => {
                    let list = res.data;
                    list.forEach(ass => {
                        ass.programName = ass.list ? ass.list.title : '-';
                        ass.typeText = ass.program ? getAssTypeName(ass.program.type) : '-';
                        ass.statusText = getAssItemStatusText(ass.status);
                    });
                    this.assList = list;
                });
            },
            getMsgList() {
                let url = `/admin/apply?sort=-update_time&pageSize=5&is_oa=2&temp_type=1,3,5&temple_id=${getTemple()}&filter[status]=4`;
                this.$axios({url, noTempleFilter: true}).then(res => {
                    this.msgList = res.data;
                })
            },
        }
    }
</script>

<style scoped lang="less">
  .content {
    overflow: auto;
  }
  .home-info-tip {
    padding: 24px 0;
    text-align: center;
    font-size: 16px;
  }
  .home-piece {
    margin-top: 16px;
  }
  .book-num-item {
    flex: 1;
    display: flex;
    justify-content: center;
    span:last-child {
      margin-left: 1em;
    }
  }
  .book-edu-chart {
    height: 180px;
  }
  .change-title {
    flex-shrink: 0;
    margin: 0 16px 0 0;
  }
  .change-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .home-ass-item {
    display: flex;
    align-items: center;
  }
  .home-ass-name {
    flex: 1;
  }
  .home-ass-btn {
    margin-left: 6px;
    cursor: pointer;
    color: @text-color-secondary;
  }
</style>
