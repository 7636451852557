<template>
  <div class="content md">
    <a-card :bordered="false" title="动态通知">
      <msg-list :list="list"></msg-list>
      <a-pagination v-bind="pagination" @change="handlePageChange" style="margin-top: 10px;text-align: right"/>
    </a-card>
  </div>
</template>

<script>

import msgList from "@/layouts/MsgList";
import {getTemple} from "../../common/js/storage";

export default {
  name: "HRMessage",
  components: {
    msgList
  },
  data() {
    return {
      list: [],
      pagination: {
          total: 0,
          current: 1,
          pageSize: 10
      },

    }
  },
  created() {
      this.getList();
  },
  methods: {
    getList() {
      let url = `/admin/apply?sort=-id&is_oa=2&temp_type=1,3,5&temple_id=${getTemple()}&filter[status]=4`;
      let {current, pageSize} = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      this.$axios({url, noTempleFilter: true}).then(res => {
          this.list = res.data;
          this.$set(this.pagination, 'total', res.page.totalCount)
      })
    },
    handlePageChange(page) {
      let pager = { ...this.pagination };
      pager.current = page.current;
      this.pagination = pager;
      this.getList();
    },
  }
}
</script>

<style scoped>

</style>
